import { Outlet } from "react-router-dom";
import Offline from '../components/Offline';
import { useState } from "react";
// import Header from "../components/Header";
// import Footer from "../components/Footer";
// import { useLocation } from 'react-router-dom';
// import { removeActive } from "../utils/utils";
// import { getMenuList } from "../Axios/pages";

const Layout = () => {

    const [online, setOnline] = useState(false);
    // const [menuRef, setMenuRef] = useState(null);
    // const [loading, SetLoading] = useState(true); 
    // const [menuList, SetMenuList] = useState([]);

    // const location = useLocation();

    if(window.navigator.onLine){
        if(!online)
            setOnline(true);
    }else {
        if(online)
            setOnline(false);
    }
    window.addEventListener('online', () => {
        if(!online)
            setOnline(true);
    });
    window.addEventListener('offline', () => {
        if(online)
            setOnline(false);
    });

    // useEffect(()=> {
    //     let active = false;
    //     getMenuList(SetLoading, 'power-menu').then((response)=>{
    //       const [data] = response['hydra:member'];
    //       if(!active) {
    //         SetMenuList(orderServices(data.menuItems));
    //       }
    //     })
    //     return () => {
    //       active = true;
    //     };
    // }, [])

    // useEffect(()=>{

    //     if(menuRef) {
    //         const activeButton = menuRef.querySelector('button.drop.active');
    //         if (activeButton) {
    //             removeActive();
    //         }
    //         const activeLink = menuRef.querySelector('.active');
    //         activeLink?.closest('.menu__list-item').querySelector('button')?.classList.add('active');
    //     }

    // }, [location, menuRef])

  
    // function handleMenuRef(ref) {
    //     setMenuRef(ref.current);
    // }

    return (
        <>
            {/* <Header handleMenuRef = {handleMenuRef} menuList={menuList} loading={loading}/> */}
            {(!online)? <Offline />: ''}
            <Outlet />
            {/* <Footer/> */}
        </>
    )
};

export default Layout;

