import React from 'react'

const OkeyHttp = () => {
  return (
    <div style={{minHeight: '100vh', backgroundColor: "black"}}>
        <h4 style={{color: '#ffff', paddingLeft: '20px'}}>ОК</h4>
    </div>
  )
}

export default OkeyHttp