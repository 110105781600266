export const orderServices = (arr) => {
    return arr.sort((a,b) => a.orders - b.orders);
}

export const removeActive = () => {
  const activeElem = document.querySelectorAll(`.drop`);
  for(let i=0; i < activeElem.length; i++){
    activeElem[i].classList.remove('active');
  }
}

export const unique = (arr, value) => {
 return arr.reduce((o, i) => {
    if (!o.find(v => v[value] === i[value])) {
      o.push(i);
    }
    return o;
  }, []);
}

export const sortDataLabel = (arr) => {
  return arr.sort(function(a, b){
    return a.label.localeCompare(b.label)
  })
}

export const filterData = (arr) => {
  return arr.filter((element) => {
    return element.name !== 'невідомо' &&  element.name !== '';
  })
}

export const formatUTC = (strDate, dateOptions, timeOptions) => {
  const date = new Date(strDate);
  const datetoUTC = date.toLocaleDateString('uk-UA', dateOptions);
  const timetoUTC = date.toLocaleTimeString('uk-UA', timeOptions);
  return `${datetoUTC} ${timetoUTC}`;
}