import { AxiosInstanceSearch } from "./axios";

export const getCityList = async (otg = '')=>{
    const cityList = await AxiosInstanceSearch.get(`pw_cities?pagination=false&otg.id=${otg}`);
    return cityList.data;
}

export const getOtgList = async ()=>{
    const cityList = await AxiosInstanceSearch.get(`pw_otgs?pagination=false`);
    return cityList.data;
}

export const getStreetList = async (city)=>{
    const streetList = await AxiosInstanceSearch.get(`/pw_streets?pagination=false&city.id=${city}`);
    return streetList.data;
}

export const getactualPodiys = async (otg, city, street, page)=>{
    const streetList = await AxiosInstanceSearch.get(`/pw_accidents?page=${page}&otg.id=${otg}&city.id=${city}&street.id=${street}`);
    return streetList.data;
}

export const getGpvList = async (city, street, building)=>{
    const streetList = await AxiosInstanceSearch.get(`/pw_accounts?pagination=false&city.id=${city}&street.id=${street}` + (building !== undefined ? `&buildingName=${building}` : ''));
    return streetList.data;
}

export const getGpvListAcc = async (acc)=>{
    const streetList = await AxiosInstanceSearch.get(`/pw_accounts?pagination=false&acc=${acc}`);
    return streetList.data;
}


export const getListAccName = async (acc, name)=>{
    const streetList = await AxiosInstanceSearch.get(`/pw_accounts?pagination=false&acc=${acc}&name=${name}`);
    return streetList.data;
}

export const getSynk = async ()=>{
    const menu = await AxiosInstanceSearch.get(`/options?option_key=successful_last_synk`);
    return menu.data;
}

export const getplanedPodiys = async (dateBefore, dateAfter, otg, city, street, page)=>{
    const streetList = await AxiosInstanceSearch.get(`/pw_accident_planeds?page=${page}&otg.id=${otg}&city.id=${city}&street.id=${street}`,
    {
        params: {
            'datePlanIn[before]': dateAfter !== 'Invalid Date' ? dateAfter : 0,
            'datePlanIn[after]': dateBefore !== 'Invalid Date' ? dateBefore : 0,
        }
    });
    return streetList.data;
}

export const getHistory = async (dateBefore, dateAfter, otg, city, street, page)=>{
    const streetList = await AxiosInstanceSearch.get(`/pw_accident_histories?page=${page}&otg.id=${otg}&city.id=${city}&street.id=${street}`,
    {
        params: {
            'datePlanIn[before]': dateAfter !== 'Invalid Date' ? dateAfter : 0,
            'datePlanIn[after]': dateBefore !== 'Invalid Date' ? dateBefore : 0,
        }
    });
    return streetList.data;
}