import './styles/global.scss';
import './styles/style.scss';
import './styles/header.scss';
import './styles/footer.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';

import React, { useEffect } from 'react';
import { RouterProvider, createBrowserRouter} from "react-router-dom";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import Layout from './pages/Layout';
import OkeyHttp from './pages/OkeyHttp';
import Grafik from './pages/Grafik';
// import GpvSearch from './pages/GpvSearch';
// import SendOff from './pages/SendOff';

const errorMsg = '404';

const router = createBrowserRouter([
  {
      path: "/",
      element: <Layout />,
      errorElement: <NoPage error={errorMsg}/>,
      children: [
          { index: true, element: <Home /> },
          // {
          //   path: "/shedule-off",
          //   element: <GpvSearch />,
          //   errorElement: <NoPage/>,
          // },
          // {
          //   path: "/send-off",
          //   element: <SendOff />,
          //   errorElement: <NoPage/>,
          // },
          {
            path: "/planovi_vidkluchenna",
            element: <Grafik type={0} />, // 0 - для планових
            errorElement: <NoPage/>,
          },
          {
            path: "/avarijni_vidkluchenna",
            element: <Grafik type={1} />, // 1 - для аварійних
            errorElement: <NoPage/>,
          },
    ], 
  },
  {
    path: "/healthcheck",
    element: <OkeyHttp />,
  }
]);

function App() {

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;