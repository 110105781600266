import { filterData, sortDataLabel } from "./utils";

export const defaultOptions = [
    {value: 0, label: 'Львів'},
    {value: 1, label: 'Сокільники'},
    {value: 2, label: 'Дубляни'},
    {value: 3, label: 'Дрогобич'},
    {value: 4, label: 'Стрий'},
    {value: 5, label: 'Червоноград'},
    {value: 6, label: 'Радехів'},
    {value: 7, label: 'Рава-Руська'},
    {value: 8, label: 'Жовква'},
    {value: 9, label: 'Золочів'},
];

export const selectOptions = (getList, setList, id) => {
  getList(id).then((response)=>{
    const data = response['hydra:member'];
    const formatData = filterData(data).map(elements=>{
        return {value: elements.id, label: elements.name}
    })

    return setList(sortDataLabel(formatData));
  }).catch((err)=>{
      console.log(err.message);
  });
}