
import React from 'react';
import { PaginationControl } from 'react-bootstrap-pagination-control';

const PaginationLoe = ({currentPage, total, changePage}) => {

  return <PaginationControl
    page={currentPage}
    between={4}
    total={total}
    limit={30}
    changePage={changePage}
    ellipsis={1}
  />
}

export default PaginationLoe